import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [0];

export const dictionary = {
		"/": [5],
		"/(app)/[language=language]": [6,[2,3]],
		"/(app)/[language=language]/enter-payment-info/[mailToken]": [7,[2,3]],
		"/(app)/[language=language]/sign-up/facility": [8,[2,3,4]],
		"/(app)/[language=language]/sign-up/membership": [9,[2,3,4]],
		"/(app)/[language=language]/sign-up/order-overview": [10,[2,3,4]],
		"/(app)/[language=language]/sign-up/personal-data": [11,[2,3,4]],
		"/(app)/[language=language]/sign-up/services": [12,[2,3,4]],
		"/(app)/[language=language]/sign-up/welcome": [13,[2,3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';